import React from "react"

const OneColTextBlock = props => {
  return (
    <div className={`text-block text-block__one-col ${props.imageSize}`}>
      <div className="text-block__content">{props.content}</div>
      <div className={`text-block__background ${props.imageSize}`}>
        {props.backgroundImage && <img src={props.backgroundImage} />}
      </div>
    </div>
  )
}

export default OneColTextBlock
