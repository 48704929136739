import React, { Component } from "react"
import Layout from "../components/global/layout"
import SEO from "../components/global/seo"
import HeroBannerHeader from "../components/global/heroBannerHeader"
import { graphql } from "gatsby"
import Instagram from "../components/global/instagram"
import OneColTextBlock from "../components/text-blocks/text-block-one-col"
import yearImage from "../images/1947-background-text.svg"
import healthImage from "../images/health-text.svg"
import careText from "../images/care-text.svg"
import ImageTextBlock from "../components/text-blocks/image_text_block"
import TwoColText from "../components/text-blocks/two-col-text"
import BannerText from "../components/text-blocks/text-banner"
import mapImage from "../images/map.svg"
import diagram from "../images/diagram.svg"
import Img from "gatsby-image"

class BrandStory extends Component {
  state = {}
  render() {
    console.log("props is: ", this.props)
    return (
      <Layout noHeaderInLayout={false} makeFooterSticky={false}>
        <SEO title="Blistex Brand Story" />

        <HeroBannerHeader
          data={this.props.data}
          text={{
            primaryTitle: "brand story",
            secondaryTitle:
              "The Blistex brand has been a global leader in lip care and lip health for over 70 years.",
          }}
        />

        <OneColTextBlock
          backgroundImage={yearImage}
          imageSize="full"
          content={
            <>
              <h2>the Blistex story</h2>
              <p>
                Blistex was founded in 1947 as a small, family-run company in
                the business of developing and marketing lip care products,
                primarily in the United States. the brand existed to create
                quality products, achieve consistent growth and become an
                innovative leader in lip care.
              </p>
            </>
          }
        />

        <ImageTextBlock
          image={this.props.data.imageBlockOne}
          imageFirst="true"
          content={
            <p>
              today, the Blistex tradition is carried on by the third generation
              of our founding family. we still believe in our original goals.
              product quality. growth. innovation. and leadership. some things
              should never change.
            </p>
          }
        />

        <TwoColText
          firstCol={
            <p className="large">
              the pursuit of quality and customer service led us to create our
              research and development facilities in 1967.
            </p>
          }
          secondCol={
            <p>
              as well as build our own manufacturing and corporate headquarters
              complex in oak brook, illinois. This move enabled us to have
              pinpoint control of every phase of our business from product
              formulation and testing to manufacturing and marketing. over the
              years, consistent growth was fuelled by constant innovation in the
              Blistex product line. new products were added and existing
              products improved as Blistex moved into worldwide lip and health
              care markets.
            </p>
          }
        />

        <BannerText
          image={this.props.data.bannerImage}
          backgroundImage="false"
          title={
            <>
              <h3>first national advertisement</h3>
              <h2>life magazine 1948</h2>
            </>
          }
        />

        <ImageTextBlock
          image={this.props.data.imageBlockTwo}
          imageFirst="true"
          content={
            <p>
              everyone’s favourite “Little Blue Jar” has evolved to become
              MedPlus, providing a unique sensation with its specialized
              ingredients along with its flavour and effectiveness that has
              earned the “Little Blue Jar” a loyal following among consumers
              worldwide.
            </p>
          }
        />

        <ImageTextBlock
          image={this.props.data.imageBlockThree}
          imageFirst="false"
          content={
            <p>
              Blistex quickly established a reputation as a leading innovator in
              lip care. in the 1950s Blistex developed lip balm sticks with new
              benefits such as flavours for consumer variety and sunscreens for
              daily protection.
            </p>
          }
        />

        <div className="no-margin-bottom">
          <BannerText
            image={mapImage}
            backgroundImage="true"
            title={
              <>
                <h3>our products are sold in over</h3>
                <h2>80 countries worldwide</h2>
              </>
            }
          />
        </div>

        <OneColTextBlock
          backgroundImage={healthImage}
          imageSize="small"
          content={
            <>
              <p>
                Blistex is the harmony of health and care to provide just the
                right solutions for any lip need.
              </p>
              <p>
                we are passionate about lips and giving them the very best care.
                our heritage in medicated lip care means we provide effective,
                high quality products every time.
              </p>
            </>
          }
        />

        <section className="brand-story-diagram">
          {/* <div className="brand-story-diagram__title">
            <h2 className="vertical-heading">the evolution of Blistex</h2>
          </div> */}
          <div className="brand-story-diagram__content">
            <div>
              <div className="brand-story-diagram__image">
                <img src={diagram} />
              </div>
              <div className="brand-story-diagram__text">
                <p>
                  daily lip care and habits that will keep your lips looking
                  great day and night.
                </p>
                <p>
                  view our tips and information and advice page on how to look
                  after your lips.
                </p>
              </div>
            </div>
            <img className="brand-story-diagram__background" src={careText} />
          </div>
        </section>

        {/*
            Uncomment the below section when Blistex send over the updated product and packaging images
          */}
        {/* <section className="product-banner">
          <h2>
            brand new look. <br></br>same trusted formulas
          </h2>
          <div
            className="product-banner__background"
            style={{ position: "relative" }}
          >
            <Img fluid={this.props.data.productBanner.childImageSharp.fluid} />
          </div>
          <div className="product-banner__product">
            <a href="/medplus/">
              <Img fluid={this.props.data.productOne.childImageSharp.fluid} />
            </a>
          </div>
          <div className="product-banner__product">
            <a href="/ultra-lip-balm/">
              <Img fluid={this.props.data.productTwo.childImageSharp.fluid} />
            </a>
          </div>
          <div className="product-banner__product">
            <a href="/intensive-moisturiser/">
              <Img fluid={this.props.data.productThree.childImageSharp.fluid} />
            </a>
          </div>
          <div className="product-banner__product">
            <a href="/intensive-moisturiser-cherry/">
              <Img fluid={this.props.data.productFour.childImageSharp.fluid} />
            </a>
          </div>
          <div className="product-banner__product">
            <a href="/lip-brilliance/">
              <Img fluid={this.props.data.productFive.childImageSharp.fluid} />
            </a>
          </div>
        </section> */}

        {/* <Instagram /> */}
      </Layout>
    )
  }
}

export default BrandStory

export const query = graphql`
  query {
    headerHeroImage: file(relativePath: { eq: "product-banner-header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageBlockOne: file(relativePath: { eq: "lip-medex.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageBlockTwo: file(relativePath: { eq: "old-lip-medex.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageBlockThree: file(relativePath: { eq: "blistik-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bannerImage: file(relativePath: { eq: "blistex-advert.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    productBanner: file(relativePath: { eq: "product-banner-background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    productOne: file(relativePath: { eq: "medplus.png" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    productTwo: file(relativePath: { eq: "ultra.png" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    productThree: file(relativePath: { eq: "intensive-moisturiser.png" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    productFour: file(relativePath: { eq: "moisturise-cherry.png" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    productFive: file(relativePath: { eq: "lip-brilliance.png" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
