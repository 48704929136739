import React from "react"

const TwoColText = props => {
  return (
    <div className="text-block__two-col">
      <div>{props.firstCol}</div>

      <div>{props.secondCol}</div>
    </div>
  )
}

export default TwoColText
