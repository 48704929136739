import React from "react"
import Img from "gatsby-image"

const ImageTextBlock = props => {
  console.log("image block props is: ", props)
  return (
    <div className="text-block-image">
      {props.imageFirst === "true" && (
        <>
          <div className="text-block-image__image">
            <Img fluid={props.image.childImageSharp.fluid} />
          </div>
          <div className="text-block-image__text">
            <div>{props.content}</div>
          </div>
        </>
      )}

      {props.imageFirst !== "true" && (
        <>
          <div className="text-block-image__text">
            <div>{props.content}</div>
          </div>
          <div className="text-block-image__image">
            <Img fluid={props.image.childImageSharp.fluid} />
          </div>
        </>
      )}
    </div>
  )
}

export default ImageTextBlock
