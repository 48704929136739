import React from "react"
import Img from "gatsby-image"

const BannerText = props => {
  return (
    <section className="banner-text">
      <div className="banner-text__content">{props.title}</div>
      <div
        className={
          "banner-text__image" +
          (props.backgroundImage === "true"
            ? " banner-text__image--background"
            : "")
        }
      >
        {props.image.childImageSharp && (
          <Img fluid={props.image.childImageSharp.fluid} />
        )}
        {!props.image.childImageSharp && <img src={props.image} />}
      </div>
    </section>
  )
}

export default BannerText
